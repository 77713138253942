.App {
  text-align: center;
}

.app-background{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #282c34;
  text-align: center;


}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

main{
  min-height: 100vh;
  background-color: #282c34;
}

.header{

  font-weight: bolder;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: aliceblue;
  background-color: #282c34;
}

.header a{
  text-decoration: none;
  color: aliceblue;
  font-size: 30px;
}

.header span{
  margin-left: 2px;
  color: #F3B51D;
  font-size: 40px;
}

.footer{
  background-color: #282c34;
  margin-top: auto;
  padding: 2em 3em;
  display: flex;
  color: aliceblue;
  justify-content: space-between;
}

.footer img{
  width: 120px;
}

.back-button{
  margin-bottom: 10px;

}

.back-button button{
  background-color: #6d7380;
  border: none;
  margin-left: 45px;
  border-radius: 5px;
  color: aliceblue;
  font-size: 20px;
  font-weight: bold;
  padding: 7px 10px;

  cursor: pointer;
}

.back-button button:hover{
  background-color: #898d9e;
}
